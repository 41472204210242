<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="voucherSummaryColumns"
        :items="items"
        :server-items-length="items.length"
        :loading="loading"
        hide-default-footer
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {} from '@mdi/js'
import { t } from '@/plugins/i18n'

// sidebar
import { money } from '@core/utils/filter'
import useVoucherReports from '../useVoucherReports'

export default {
  filters: { money },

  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },

  setup() {
    const { voucherSummaryColumns } = useVoucherReports()

    return {
      voucherSummaryColumns,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
