import axios from '@axios'

// 3 months ~ 30s
const timeout = 60000 * 5

const downloadReport = (id, type = 'sales-reports') =>
  axios
    .get(`/admin/${type}/${id}/download.xlsx`, {
      responseType: 'blob',
      timeout,
    })
    .then(({ data }) => {
      const xlsxBlobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      window.open(xlsxBlobUrl)
    })

/**
 * ==== Sales Report ===
 */

const fetchSalesSummary = queryParams => axios.get('/admin/checkout-reporting/sales-summary', { params: queryParams, timeout })

const fetchSalesItems = queryParams => axios.get('/admin/checkout-reporting/sales-items', { params: queryParams, timeout })

const fetchCancelRefundItems = queryParams => axios.get('/admin/checkout-reporting/cancel-refund-items', { params: queryParams, timeout })

const downloadSalesReport = id => downloadReport(id, 'sales-reports')

const fetchSalesReport = id => axios.get(`/admin/sales-reports/${id}`)

const deleteSalesReport = id => axios.delete(`/admin/sales-reports/${id}`)

const fetchSalesReports = queryParams => axios.get('/admin/sales-reports', { params: queryParams, timeout })

const storeSalesReport = data => axios.post('/admin/sales-reports', data, { timeout })

/**
 * ==== Voucher Report ===
 */

const fetchVoucherPurchasesSummary = queryParams => axios.get('/admin/voucher-reporting/purchases-summary', { params: queryParams, timeout })

const fetchVoucherReports = queryParams => axios.get('/admin/voucher-reports', { params: queryParams, timeout })

const downloadVoucherReport = id => downloadReport(id, 'voucher-reports')

const fetchVoucherReport = id => axios.get(`/admin/voucher-reports/${id}`)

const resendVoucherReport = (id, data) => axios.post(`/admin/voucher-reports/${id}/resend`, data, { timeout })

const storeVoucherReport = data => axios.post('/admin/voucher-reports', data, { timeout })

/**
 * ==== Service Report ===
 */

const fetchServicePurchasesSummary = queryParams => axios.get('/admin/service-reporting/purchases-summary', { params: queryParams, timeout })

const fetchServiceReports = queryParams => axios.get('/admin/service-reports', { params: queryParams, timeout })

const downloadServiceReport = id => downloadReport(id, 'service-reports')

const fetchServiceReport = id => axios.get(`/admin/service-reports/${id}`)

const resendServiceReport = (id, data) => axios.post(`/admin/service-reports/${id}/resend`, data, { timeout })

const storeServiceReport = data => axios.post('/admin/service-reports', data, { timeout })

/**
 * ==== Tangible Report ===
 */

const fetchTangiblePurchasesSummary = queryParams => axios.get('/admin/tangible-reporting/purchases-summary', { params: queryParams, timeout })

const fetchTangibleReports = queryParams => axios.get('/admin/tangible-reports', { params: queryParams, timeout })

const downloadTangibleReport = id => downloadReport(id, 'tangible-reports')

const fetchTangibleReport = id => axios.get(`/admin/tangible-reports/${id}`)

const resendTangibleReport = (id, data) => axios.post(`/admin/tangible-reports/${id}/resend`, data, { timeout })

const storeTangibleReport = data => axios.post('/admin/tangible-reports', data, { timeout })

/**
 * ==== Order Report ===
 */

const downloadOrderReport = id => downloadReport(id, 'order-reports')

const fetchOrderReport = id => axios.get(`/admin/order-reports/${id}`)

const deleteOrderReport = id => axios.delete(`/admin/order-reports/${id}`)

const fetchOrderReports = queryParams => axios.get('/admin/order-reports', { params: queryParams, timeout })

const storeOrderReport = data => axios.post('/admin/order-reports', data, { timeout })

export {
  fetchSalesSummary,
  fetchSalesItems,
  fetchCancelRefundItems,
  downloadSalesReport,
  fetchSalesReport,
  deleteSalesReport,
  fetchSalesReports,
  storeSalesReport,
  fetchVoucherPurchasesSummary,
  fetchVoucherReports,
  downloadVoucherReport,
  fetchVoucherReport,
  resendVoucherReport,
  storeVoucherReport,
  fetchServicePurchasesSummary,
  fetchServiceReports,
  downloadServiceReport,
  fetchServiceReport,
  resendServiceReport,
  storeServiceReport,
  fetchTangiblePurchasesSummary,
  fetchTangibleReports,
  downloadTangibleReport,
  fetchTangibleReport,
  resendTangibleReport,
  storeTangibleReport,
  downloadOrderReport,
  deleteOrderReport,
  fetchOrderReports,
  fetchOrderReport,
  storeOrderReport,
}
